<template>
  <AppTextField v-maska:[options] v-model="dataInput" v-bind="attrs">
    <template v-for="(index, scopedSlot) in $slots" #[scopedSlot]="data">
      <slot :name="scopedSlotName" v-bind="data" />
    </template>
    <template v-for="(index, slot) in $slots" #[slot]>
      <slot :name="slot" />
    </template>
  </AppTextField>
</template>


<script setup>
import { vMaska, Mask } from "maska";

const emit = defineEmits(["input", "update:modelValue"]);
const props = defineProps(["modelValue"]);

const attrs = useAttrs();

const options = reactive({
  mask: attrs.mask,
  eager: true,
});

const mask = new Mask({ mask: attrs.mask });

const dataInput = computed({
  get: () => props.modelValue,

  set: (value) => emit("update:modelValue", mask.unmasked(value)),
});
</script>
 


